<template>
  <b-container>
    <b-row align-h="center" class="py-3 py-sm-4 py-md-5">
      <b-col class="col-auto col-sm-9 col-lg-6 pt-lg-5">
        <b-card style="border-radius: 0" border-variant="white" class="mb-2">
          <p class="text-medium">
            {{ $t("page.welcome.description") }}
          </p>
        </b-card>
        <b-card style="border-radius: 0" border-variant="white">
          <validation-observer ref="accountForm" v-slot="{ valid }">
            <b-form>
              <b-form-group
                label-class="font-weight-bold"
                :label="$t('page.welcome.genderQuestion')"
              >
                <validation-provider
                  immediate
                  name="Gender"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-radio-group
                    stacked
                    required
                    :checked="gender"
                    :state="getValidationState(validationContext)"
                    @input="setGender($event)"
                  >
                    <b-form-radio id="male" value="male">
                      {{ $t("male") }}
                    </b-form-radio>
                    <b-form-radio id="female" value="female">
                      {{ $t("female") }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-class="font-weight-bold"
                :label="$t('page.welcome.ageQuestion')"
              >
                <validation-provider
                  immediate
                  name="Age"
                  :rules="{ required: true, age: true }"
                  v-slot="validationContext"
                >
                  <b-form-input
                    required
                    type="number"
                    min="0"
                    :value="age"
                    :state="getValidationState(validationContext)"
                    @input="setAge($event)"
                    style="width: 100px"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button
                    :disabled="!valid"
                    @click="goToSetup()"
                    class="text-small px-4 py-2 text-uppercase"
                  >
                    {{ $t("button.next") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("settings", ["environment"]),

    ...mapState("stimulus", ["permission", "age", "gender"]),
  },
  
  mounted(){
    window.addEventListener('beforeunload',this.handleBeforeUnload)
  },

  beforeDestroy(){
    window.removeEventListener('beforeunload',this.handleBeforeUnload)
  },

  methods: {
    ...mapMutations("stimulus", {
      setPermision: "SET_PERMISSION",
      setAge: "SET_AGE",
      setGender: "SET_GENDER",
    }),

    handleBeforeUnload(event){
      event.preventDefault();
      const confirmationMessage="Are you sure you want to leave?\nYour participation will not count if you leave now!"
      event.returnValue = confirmationMessage; // some browsers require returnValue to be set
      return confirmationMessage;
    },

    goToSetup() {
      this.$router.push({
        name: "setup",
        params: { pathMatch: this.environment },
      });
    },

    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched) return valid ? null : valid;
      else return null;
    },
  },
};
</script>

<style scoped>
.text-small {
  font-size: 0.7em !important;
}
.text-medium {
  font-size: 1.2em;
}
</style>